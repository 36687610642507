<template>
  <article
    :id="product.id"
    :aria-label="product.title"
    class="product_preview c12"
    itemprop="item"
    itemscope
    itemtype="https://schema.org/Product"
    :class="{ opened: isProductOpened }"
    :style="{ 'margin-bottom': setMarginBottom ? '120px' : '0' }">
    <div class="anchor-product-to-scroll" />
    <SEO
      :name="product.title"
      :canonical="product._canonical"
      :image="product.image?.default_source"
      :reference="getProductGtinOrId('id')"
      :gtin="getProductGtinOrId('gtin')" />
    <div
      :id="product.id + 'anchor'"
      class="anchor" />
    <div
      :id="product.id + 'anchor-product'"
      class="anchor-product" />
    <div
      v-if="isFirstItem"
      :id="`first_page_${currentPage}`"
      ref="edgeItem"
      class="edge-item edge-item__first"
      :dataPage="currentPage"
      :dataFirst="firstItemPage"
      :class="{ 'edge-item--full': isFirstItemPage }" />
    <div
      v-if="isLastItem"
      :id="`last_page_${currentPage}`"
      ref="edgeItem"
      class="edge-item edge-item__last"
      :dataPage="currentPage" />
    <div
      class="card"
      data-synth="LOCATOR_PRODUCT_PREVIEW_LIST">
      <div class="obverse">
        <ProductHeader
          :product="product"
          :selected-image="selectedImage()"
          :colors-collection="colorsCollection"
          :current-product="currentProduct"
          :actual-stock="actualStock"
          :need-to-update-stock="needGetStock"
          :grid-type="gridType"
          :type-of-variant="getSelectVariantTitle"
          :is-coming-soon="color?.coming_soon || false"
          :product-position="position"
          :retail-media-tracking="retailMediaTracking"
          @update-color="updateColor"
          @update-size="addToCart"
          @sizeOvered="sizeOvered"
          @sizeLeft="sizeLeft"
          @update-stock="updateStock" />
        <ProductBody
          v-model:units="productUnits"
          :product="product"
          :color="color"
          :colors-collection="colorsCollection"
          :grid-type="gridType"
          :badges="product.badges"
          :force-show-brand="forceShowBrand"
          :priceSizeOvered="priceSizeOvered"
          :sizeOvered="selectedSize"
          :isAddingToCart="isAddingToCart"
          :isGiftCard="product.isGiftCard"
          :providers="product?.provider"
          :retail-media-tracking="retailMediaTracking"
          :actualStock="actualStock"
          :showSelectCheckbox="showSelectCheckbox"
          :product-position="position"
          @update-color="updateColor"
          @update-size="updateSize"
          @handle-add-wishlist="handleAddWishlist"
          @handle-delete-wishlist="handleDeleteWishlist"
          @toggleSelection="handleSelection"
          @update-stock="updateStock" />
      </div>
    </div>

    <div
      v-if="isProductOpened"
      class="modal-actions-mobile-todo modal-actions-mobile-inner-overlay"
      :class="{ 'modal-actions-mobile-inner-overlay-plp': isPlp }"
      @click="closeModal()" />
    <div
      v-if="isProductOpened"
      class="modal-actions-mobile-todo modal-actions-mobile-inner-overlay --transparent"
      :class="{ 'modal-actions-mobile-inner-overlay-plp': isPlp }"
      @click="closeModal()" />

    <transition name="modal-transition">
      <SelectorProductContainerMobile
        v-if="isProductOpened"
        :colors-collection="colorsCollection"
        :actual-stock="actualStock"
        :need-to-update-stock="needGetStock"
        :grid-type="gridType"
        :type-of-variant="getSelectVariantTitle"
        :hide-sizes-block="showOnlyColors"
        :is-coming-soon="color.coming_soon || false"
        @update-stock="updateStock"
        @update-color="updateColor"
        @update-size="addToCart" />
    </transition>
  </article>
</template>

<script>
  import { defineAsyncComponent, getCurrentInstance } from 'vue';
  import ProductBody from './Body/ProductBody.vue';
  import ProductHeader from './Header/ProductHeader.vue';
  import StockSchema from 'CommonUtils/schemas/stock';

  import SEO from './SEO.vue';

  import CartActionsMixin from 'CommonMixins/cartActionsLogic';
  import ProductPreviewMixin from 'CommonMixins/productPreviewLogic';
  import GRID_TYPES from 'CommonUtils/gridTypes';
  import UtilsCartObject from 'CommonUtils/formatCartObject';
  import UtilsAnalytics from 'CommonUtils/analytics';
  import { getProductPreviewImages } from 'CommonUtils/productImage';
  import marketplace from 'CommonUtils/marketplace';

  import { mapGetters, mapActions } from 'vuex';
  import NavigatorDetect from 'CommonMixins/navigatorDetect';

  import CART_ACTION_TYPES from 'CommonUtils/cartActionTypes';
  import colorHasPriceRange from 'CommonUtils/colorHasPriceRange';
  import { colorHasPromoRange } from 'CommonUtils/promotions/promotionsUtils';
  import UtilsURL from 'CommonUtils/url';
  import AlgoliaEvents from 'CommonUtils/algoliaEvents.js';

  export default {
    name: 'ProductPreview',
    components: {
      ProductBody,
      ProductHeader,
      SelectorProductContainerMobile: defineAsyncComponent(() =>
        import('CommonComponents/Product/Selector/Mobile/SelectorProductContainerMobile.vue'),
      ),
      SEO,
    },
    mixins: [CartActionsMixin, ProductPreviewMixin, NavigatorDetect],
    inject: ['assetsBase'],
    provide: function () {
      return {
        getDataLayer: () => {
          const isProductArtwork = this.product?.product_store_datalayer;
          if (isProductArtwork) return { product_store_datalayer: toRaw(isProductArtwork) };
          return this.dataLayer || {};
        },
        getCurrentSeletedColor: () => (this.color ? this.color.title : ''),
        getColorsCollection: () => (this.colorsCollection ? this.colorsCollection : []),
        productUri: computed(() => this.getProductURIWithParams),
        productData: this.dataLayer,
        productTitle: () => this.product.title,
        productWrapper: this.getWrapperId,
        isRichRelevance: Boolean(this.product?.richRelevanceUrl),
        crossSellingType: this.crossSellingType,
        mustShowRecommendedPrice: this.product?.mustShowRecommendedPrice,
        sizeGuideUrl: this.product?.size_guide,
        isTicketsProduct: this.product?.isTicketsProduct,
        ticketProductUrl: this.product?.product_url,
        isGiftCard: this.product.isGiftCard,
        currentPage: this.currentPage,
      };
    },
    props: {
      product: {
        type: Object,
        required: true,
      },
      gridType: {
        type: String,
        default: GRID_TYPES.LITTLE_PRODUCTS,
      },
      page: {
        type: Number,
        default: 0,
      },
      currentPage: {
        type: Number,
        default: 0,
      },
      firstItemPage: {
        type: Number,
        default: 0,
      },
      isFirstItem: {
        type: Boolean,
        default: false,
      },
      isLastItem: {
        type: Boolean,
        default: false,
      },
      dataLayer: {
        type: Object,
        default: () => {},
      },
      forceShowBrand: {
        type: Boolean,
        default: false,
      },
      isProductStore: {
        type: Boolean,
        default: false,
      },
      crossSellingType: {
        type: [String, undefined],
        required: false,
        default: undefined,
      },
      addToCartIndexPushed: {
        type: Number,
        default: 0,
      },
      isPlp: {
        type: Boolean,
        default: false,
      },
      position: {
        type: Number,
        required: true,
      },
      showSelectCheckbox: {
        type: Boolean,
        default: false,
      },
      setMarginBottom: {
        type: Boolean,
        default: false,
      },
      retailMediaTracking: {
        type: [String, undefined],
        default: undefined,
      },
    },
    emits: ['indexPushedUpdate', 'toggleSelection'],
    data() {
      return {
        GRID_TYPES,
        color: this.getDefaultColor(),
        colorsCollection: this.product._my_colors,
        actualStock: [],
        productUnits: 1,
        currentInstance: getCurrentInstance(),
        priceSizeOvered: null,
        timerSizeOvered: null,
        selectedSize: null,
        isAddingToCart: false,
      };
    },
    computed: {
      ...mapGetters('selectorState', {
        isModalSizeOpen: 'getModalStatus',
        currentWrapper: 'getCurrentWrapper',
        currentProduct: 'getCurrentProduct',
        showOnlyColors: 'getShowOnlyColors',
      }),
      ...mapGetters('cart', {
        getFullCartData: 'getFullCartData',
        algoliaUser: 'getAlgoliaUserData',
      }),
      ...mapGetters('page', {
        isAMV: 'getIsAMV',
        baseUrl: 'getBaseUrl',
        internals: 'getInternals',
        getSearchSource: 'getSearchSource',
        algoliaInfo: 'getAlgoliaInfo',
      }),
      isFirstItemPage() {
        return this.firstItemPage === this.currentPage;
      },
      showModalMobile() {
        return (
          this.currentProduct === this.product.id && (!this.currentWrapper || this.currentWrapper === this.getWrapperId)
        );
      },
      isProductOpened() {
        return (
          this.isModalSizeOpen &&
          this.currentProduct === this.product.id &&
          (!this.currentWrapper || this.currentWrapper === this.getWrapperId)
        );
      },
      needUnits() {
        const sale_information = this.product.sale_info;
        if (!sale_information || !sale_information.subType) return false;
        const isBasic = sale_information.subType === 'basic';
        if (isBasic) {
          const subtype = sale_information.values.type;
          if (subtype === 'units') return true;
        } else if (this._gift_card) return true;
        return true;
      },
      selectedColor() {
        if (this.product.priority_image && this.product.priority_image.color) return this.product.priority_image.color;
        if (this.color) {
          return this.color.title;
        }
        return '';
      },
      selectedSku() {
        let sku = null;
        if (this.product._single_sku) sku = this.product._single_sku.reference;
        if (this.color) {
          sku = this.color.sku;
          if (!sku) {
            sku = this.product.skus.find((sku) => sku?.color?.title === this.color.title)[0].id;
          }
        }
        return sku;
      },
      needGetStock() {
        const FIVE_MIN = 5 * 60 * 1000;

        if (this.product.isTicketsProduct) {
          return false;
        }

        return (
          this.actualStock.length === 0 ||
          !this.actualStock.some((x) => x.color === this.color.title) ||
          Date.now() - this.actualStock.find((x) => x.color === this.color.title).updateDate > FIVE_MIN
        );
      },
      variantTitle() {
        const variant = this.color?.variants[0]?.variant || this.firstVariant?.value;
        if (variant?.unique && variant?.empty) return '';

        const title = variant?.fullTitle || variant?.title || '';
        return title.toUpperCase();
      },
      firstVariant() {
        return this.product?._my_colors[0]?.variants[0]?.variant;
      },
      getSelectVariantTitle() {
        if (this.variantTitle === '') return '';

        if (
          this.color?.variants?.length > 1 ||
          this.firstVariant?.value?.toUpperCase() !== this.$t('product.unique_size')
        ) {
          if (this.product?.isGiftCard) {
            return this.$t('product.select_variant__upper', { typeOfVariant: this.variantTitle });
          }
          return this.$t('product.choose_variant__upper', { typeOfVariant: this.variantTitle });
        }

        if (this.variantTitle.toUpperCase() === 'TALLA') {
          return this.$t('product.unique_size');
        }

        return '';
      },
      productDataForCartMapping() {
        const units = this.productUnits || 1;
        const productData = { ...this.product, units };
        return productData;
      },
      getWrapperId() {
        return this.currentInstance?.parent?.uid;
      },
      getProductURIWithParams() {
        return UtilsURL.getFullUrlWithQuery({
          url: this.product._base_url,
          baseUrl: this.baseUrl,
          query: this.internals,
          search: { color: this.color ? this.color.title : '' },
          ...(this.product.schoolId && {
            uniforms: {
              ...this.internals.req?.moonshine?.params,
              schoolId: this.product.schoolId,
              parentProduct: this.product.id,
              parentCategoryId: this.product.categories?.at(-1)?.id,
            },
          }),
        });
      },
      isAlgoliaEnabled() {
        return this.algoliaInfo?.enabled && this.getSearchSource === 'algolia';
      },
    },
    watch: {
      color: function (newValue, oldValue) {
        const oldColor = this.colorsCollection.find((x) => x.title === oldValue.title);

        if (oldColor) oldColor.preselected = false;

        const newColor = this.colorsCollection.find((x) => x.title === newValue.title);

        if (newColor) newColor.preselected = true;
      },
      'product.stock': {
        handler(stock) {
          this.handleStock(stock);
        },
        deep: true,
      },
    },
    mounted() {
      if (this.isFirstItem || this.isLastItem) this.handlerObserver();
      if (this.product?.stock) {
        this.handleStock(this.product.stock);
      }
    },
    created() {
      AlgoliaEvents.initAlgoliaEventsUrl(this.algoliaInfo?.events);
    },
    methods: {
      ...mapActions('wishlist', ['addWishlist', 'deleteWishlist']),
      ...mapActions('page', ['getStock']),
      ...mapActions('selectorState', ['showModal']),
      ...mapActions('analytics', {
        incrementAnalyticsIndexPushed: 'incrementIndexPushed',
      }),
      ...mapGetters('analytics', ['getIndexPushed']),
      colorHasPriceRange,
      closeModal() {
        const payload = { isOpen: false };
        this.showModal(payload);
      },
      getAnalyticsIndexPushed(payload) {
        return this.getIndexPushed()(payload);
      },
      handlerObserver() {
        const currentNode = this.$refs.edgeItem;

        const topPlacements = document.querySelectorAll('.js-products_list-item--first');
        if (this.currentPage === 1 && topPlacements)
          topPlacements.forEach((placement) => this.topPlacementObserver.observe(placement));

        if (this.isFirstItem) this.observerFirst.observe(currentNode);
        if (this.isLastItem) this.observerLast.observe(currentNode);
      },
      handleStock(stock) {
        if (stock?.status) {
          this.color = this.getDefaultColor();
          this.colorsCollection = this.product._my_colors;
          this.colorsCollection.forEach((color) => {
            Array.isArray(color?.variants) &&
              color.variants.forEach((colorVariant) => {
                colorVariant.show_notify = stock.show_notify;
                colorVariant.status = stock.status;
                colorVariant.add_to_cart = stock.status;
                colorVariant.available = StockSchema.hasAvailableStatus(colorVariant.status);
                this.setVariantPricePreferred(colorVariant, stock);
              });
            const add_to_cart = Array.isArray(color?.variants)
              ? StockSchema.getSkusGroupStatus(color.variants.map((sku) => sku.status))
              : StockSchema.STATUS.NOT_AVAILABLE;
            const isComingSoon = StockSchema.hasComingSoonStatus(add_to_cart);
            const isAvailable = StockSchema.hasAvailableStatus(add_to_cart);
            const showPrice = StockSchema.hasToShowPrice(add_to_cart);
            color.not_available = !isAvailable;
            color.coming_soon = isComingSoon;
            color.show_notify = Array.isArray(color?.variants) && color.variants.some((product) => product.show_notify);
            color.add_to_cart = add_to_cart;
            color.status = add_to_cart;
            color.showPrice = showPrice;
            color.hasPromoRange = colorHasPromoRange(color.variants);
            color.hasPriceRange = this.colorHasPriceRange(color.variants);
          });
        }
      },
      updateColor(color) {
        this.color = color;
      },
      sizeOvered(size) {
        clearTimeout(this.timerSizeOvered);
        const { variant } = UtilsCartObject.getVariantById(this.product._my_colors, size.sku);
        if (!variant) return;
        const { sale_price, price, discount, pumOrPackComposition } = variant;
        this.selectedSize = size;
        if (price) {
          this.priceSizeOvered = {
            unit_price: price,
            unit_sale_price: sale_price ?? price,
            discount: discount ?? 0,
            ...(pumOrPackComposition && { pumOrPackComposition }),
          };
        }
      },
      sizeLeft() {
        this.timerSizeOvered = setTimeout(() => {
          this.priceSizeOvered = null;
          this.selectedSize = null;
        }, 500);
      },
      addToCart(size) {
        if (Array.isArray(size)) {
          for (const sizeItem of size) {
            if (this.isAMV) {
              this.prepareAddToCartAMV(sizeItem);
            } else {
              this.updateSize(sizeItem);
            }
          }
        } else {
          if (this.isAMV) {
            this.prepareAddToCartAMV(size);
          } else {
            this.updateSize(size);
          }
        }
      },
      prepareAddToCartAMV(size) {
        const amvCartData = {};
        amvCartData.reference = size && size.reference && size.reference.length > 4 ? size.reference.substring(4) : '';
        amvCartData.gtin = size.gtin;
        amvCartData.units = '1';
        amvCartData.url = this.product._uri;
        this.addToCartAMV(amvCartData);
      },
      async updateSize(size, shippingMethod = '') {
        this.isAddingToCart = true;

        const cartObject = {
          product: this.productDataForCartMapping,
          currentSize: size,
          cartData: this.getFullCartData,
          shipping_method: shippingMethod,
          vendor: this.dataLayer?.vendor,
          school: this.product?.schoolId,
          thirdSize: this.product?.thirdQuery,
          isGiftCard: this.product?.isGiftCard,
        };
        const objectId = this.color.variants.find((variant) => variant.id === size.id)?.objectId;
        const ADD_PRODUCT_TO_CART = UtilsCartObject.formatCartObject(cartObject);
        const product_store_datalayer = this.productDataForCartMapping?.product_store_datalayer;
        const cross_selling = product_store_datalayer ? 'product_store' : this.crossSellingType;
        ADD_PRODUCT_TO_CART.line_items[0].data_layer.position = this.position;
        const cartSuccessAction = this.isMobileDevice ? CART_ACTION_TYPES.TOAST : CART_ACTION_TYPES.BASKET;

        if (this.isAlgoliaEnabled && this.product.algoliaData?.query_id) {
          AlgoliaEvents.setProductDataLayerData(
            ADD_PRODUCT_TO_CART.line_items[0].data_layer,
            this.product.algoliaData?.query_id,
            this.product.algoliaData?.index_name,
            objectId,
          );

          const payload = {
            index: this.product.algoliaData?.index_name,
            queryID: this.product.algoliaData?.query_id,
            objectIDs: [objectId],
            objectData: [
              {
                price: ADD_PRODUCT_TO_CART.line_items[0].data_layer.price.f_price,
                discount: ADD_PRODUCT_TO_CART.line_items[0].data_layer.price.discount,
                quantity: ADD_PRODUCT_TO_CART.line_items[0].data_layer.quantity,
              },
            ],
            value:
              ADD_PRODUCT_TO_CART.line_items[0].data_layer.price.f_price *
              ADD_PRODUCT_TO_CART.line_items[0].data_layer.quantity,
            currency: ADD_PRODUCT_TO_CART.line_items[0].data_layer.price.currency,
            userToken: this.algoliaUser.algoliaUserToken,
            authenticatedUserToken: this.algoliaUser.algoliaAuthenticatedUserToken,
          };
          AlgoliaEvents.sendAddedToCartObjectIDsAfterSearch(payload);
        }

        const sendUpdateItemCartResult = await this.sendUpdateItemCart(
          { line_items: ADD_PRODUCT_TO_CART.line_items },
          false,
          cartSuccessAction,
        );

        if (this.crossSellingType === 'cdp-carousel') {
          ADD_PRODUCT_TO_CART.line_items[0].data_layer['cdp-id'] = this.dataLayer?.cdp_id;
        }
        const addProductToCartAnalytic = UtilsAnalytics.addProductToCartHandler(
          ADD_PRODUCT_TO_CART.line_items[0].data_layer,
          cross_selling,
          this.addToCartIndexPushed,
        );
        addProductToCartAnalytic(sendUpdateItemCartResult);
        this.$emit('indexPushedUpdate');
        this.isAddingToCart = false;
      },
      updateStock() {
        if (!this.needGetStock || !this.color) return;
        const self = this;
        let stockIds;
        const payload = {
          type: 'skus',
          ids: this.color.variants
            ? this.color.variants.map((variant) => {
                stockIds = [variant.stockReference];
                if (variant.providers?.length) {
                  variant.providers.forEach((provider) => {
                    if (provider.stockReference !== variant.stockReference) {
                      stockIds.push(provider.stockReference);
                    }
                  });
                }
                return stockIds;
              })
            : [],
        };
        if (!payload.ids.length) return;
        this.getStock(payload)
          .then((response) => {
            const data = !response || !response.data ? StockSchema.getDefaultStockResponse(payload.ids) : response.data;
            if (!response || !response.data) {
              console.error('[ProductPreview] Error fetching stock. Giving default response.');
            }
            self.setStock(data);
          })
          .catch((error) => {
            const data = StockSchema.getDefaultStockResponse(payload.ids);
            console.error('[ProductPreview] Error fetching stock. Giving default response.', error);
            self.setStock(data);
          });
      },
      setStock(data) {
        this.updateVariantsWithPricePreferred(data.products);
        const variantsStock = this.getVariantsWithStock(data.products);
        const add_to_cart = StockSchema.getSkusGroupStatus(variantsStock.map((sku) => sku.status));
        const isComingSoon = StockSchema.hasComingSoonStatus(add_to_cart);
        const isAvailable = StockSchema.hasAvailableStatus(add_to_cart);
        const show_notify = variantsStock.some((variant) => variant.show_notify);
        this.color.not_available = !isAvailable;
        this.color.coming_soon = isComingSoon;
        this.color.show_notify = show_notify;
        this.color.add_to_cart = add_to_cart;
        this.actualStock = this.actualStock.filter((sku) => sku.color != this.color.title);
        this.color.hasPriceRange = this.colorHasPriceRange(variantsStock);
        this.actualStock.push({
          color: this.color.title,
          stock: variantsStock,
          shipping: data,
          updateDate: Date.now(),
        });
      },
      getVariantDescription(variant) {
        return variant.variant.value;
      },
      getVariantsWithStock(realSizes) {
        return this.color.variants.map((variant) => {
          return {
            description: this.getVariantDescription(variant),
            sku: variant.sku,
            gtin: variant.gtin,
            reference: variant.isMarketplace ? variant.reference : variant.sku,
            stockReference: variant.stockReference,
            eci_unique_code: variant.eci_unique_code,
            status: realSizes[variant.stockReference].status,
            available: StockSchema.hasAvailableStatus(realSizes[variant.stockReference].status),
            coming_soon: StockSchema.hasComingSoonStatus(realSizes[variant.stockReference].status),
            show_notify: realSizes[variant.stockReference].show_notify,
            previewDate: realSizes[variant.stockReference].previewDate,
            id: variant.id,
            delivery_options: variant.delivery_options,
            from_searcher: variant.from_searcher,
            offer_type: variant.offer_type,
            offer_id: variant.offer_id,
            flags: variant.flags,
            common_sku: variant.common_sku,
            variant: variant.variant,
            price: variant.price,
            sale_price: variant.sale_price,
            discount: variant.discount,
            all_promos: variant.all_promos,
            providers: this.updateProvidersStock(realSizes, variant),
            principal_agrupation: variant.principal_agrupation,
          };
        });
      },
      updateProvidersStock(providers, variant) {
        if (!providers) return undefined;
        const providersValues = Object.values(providers);
        const providersKeys = Object.keys(providers);
        return providersKeys
          .map((providerKey, index) => {
            return providerKey.split(':')[0] === variant.stockReference.split(':')[0]
              ? {
                  name:
                    variant.providers.find((provider) => provider.stockReference === providerKey)?.name ??
                    'El Corte Ingles, S.A.',
                  provider_id: providerKey.split(':')[1],
                  type: variant.providers.find((provider) => provider.stockReference === providerKey)?.type ?? 'eci',
                  stockReference: providerKey,
                  status: providersValues[index].status,
                }
              : undefined;
          })
          .filter((provider) => provider !== undefined);
      },
      handleAddWishlist(commonSku, productId) {
        this.addWishlist({ commonSku, productId }).then(() => {
          this.colorsCollection = this.colorsCollection.map((color) => {
            if (color.common_sku === commonSku) color.is_favorite = true;
            return color;
          });
        });
        const productWishlistPushedIndexCount = UtilsAnalytics.sendAddToWishListClick(
          this.dataLayer,
          this.getAnalyticsIndexPushed(UtilsAnalytics.PRODUCT_PUSH_TYPES.WISHLIST),
        );
        this.incrementAnalyticsIndexPushed({
          type: UtilsAnalytics.PRODUCT_PUSH_TYPES.WISHLIST,
          increment: productWishlistPushedIndexCount,
        });
      },
      handleDeleteWishlist(commonSku) {
        this.deleteWishlist(commonSku).then(() => {
          this.colorsCollection = this.colorsCollection.map((color) => {
            if (color.common_sku === commonSku) color.is_favorite = false;
            return color;
          });
        });
      },
      getProductGtinOrId(property) {
        if (this.product && this.product._my_colors && this.product._my_colors.length) {
          const sku = this.product._my_colors[0].variants?.find((sku) => sku.gtin);
          if (sku) {
            if (property == 'id') {
              return sku.id;
            }
            return sku.gtin;
          }
          return this.product._my_colors[0].variants[0]?.id;
        }
        return undefined;
      },
      getDefaultColor() {
        return this.product._my_colors?.find((color) => color.preselected);
      },
      selectedImage() {
        const wrappedColors = { _my_colors: this.colorsCollection };
        const images = getProductPreviewImages(wrappedColors, this.gridType, this.assetsBase);
        const artworkImage = this.product.sm_artwork?.image;
        if (artworkImage?.image) {
          images.splice(0, 0, artworkImage.image);
        } else if (artworkImage?.position && images.length) {
          const artworkSelectedImage =
            artworkImage.position > images.length - 1 ? images.length - 1 : artworkImage.position;
          images.splice(0, 0, images.splice(artworkSelectedImage, 1)[0]);
        }
        return images;
      },
      updateVariantsWithPricePreferred(realSizes) {
        this.color.variants?.forEach((variant) => {
          const variantStock = realSizes[variant.stockReference];
          this.setVariantPricePreferred(variant, variantStock);
        });
      },
      setVariantPricePreferred(variant, stock) {
        const salesReference = variant.stockReference;
        if (!salesReference) return;
        const mkpPreferredPrice = marketplace.updatePriceProductPreviewFromMarketplace(stock, salesReference);
        if (!mkpPreferredPrice) return;
        variant.price = mkpPreferredPrice.unit_price ?? variant.price;
        variant.sale_price = mkpPreferredPrice.unit_sale_price ?? variant.sale_price;
        variant.discount = mkpPreferredPrice.discount ?? false;
      },
      handleSelection(eventData) {
        this.$emit('toggleSelection', { isSelected: eventData, product: this.product });
      },
    },
  };
</script>

<style lang="less" scoped>
  .anchor {
    position: absolute;
    top: -55px;
  }

  .anchor-product {
    position: absolute;
    top: 15vh;
  }

  .product_preview {
    .edge-item {
      position: absolute;
      width: 100%;
      visibility: hidden;
      &__first {
        top: 0;
      }
      &__last {
        bottom: 0;
      }

      &--full {
        height: 100%;
      }
    }
  }
  .modal-transition-enter-active,
  .modal-transition-leave-active {
    transition: transform 0.5s;
    transform: translateY(100%);
  }

  .modal-transition-enter-to,
  .modal-transition-leave {
    transform: translateY(0);
  }
</style>

<style lang="less">
  .product_preview {
    img {
      transition: transform 0.3s;
    }

    &:has(&-title:active),
    .product_link:active {
      img {
        transform: scale(1.05);
        transition: transform 0.1s;
      }
    }
  }
</style>
