<template>
  <h2>
    <a
      v-if="clickable"
      data-custom-brand
      class="product_preview-title"
      :class="customClass"
      :title="title"
      :href="isCustomUrl ? url : productUri"
      @click="sendProductClick">
      {{ title }}
    </a>
    <a
      v-else
      class="product_preview-title"
      :title="title">
      {{ title }}
    </a>
  </h2>
</template>

<script>
  import UtilsAnalytics from 'CommonUtils/analytics';
  import { mapGetter } from 'CommonUtils/store/state.js';
  import { defer } from 'CommonUtils/operations/defer';
  import UtilsCookies from 'CommonUtils/cookies.js';
  import AlgoliaEvents from 'CommonUtils/algoliaEvents.js';

  import API from 'Services/API';
  const APIService = new API('/api/vuestore');

  export default {
    name: 'PreviewLinkToPDP',
    props: {
      customClass: {
        type: String,
        required: false,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      objectId: {
        type: String,
        required: false,
        default: '',
      },
      title: {
        type: String,
        required: true,
      },
      url: {
        type: String,
        required: true,
      },
      clickable: {
        type: Boolean,
        default: true,
      },
      isCustomUrl: {
        type: Boolean,
        default: false,
      },
      retailMediaTracking: {
        type: [String, undefined],
        default: undefined,
      },
      algoliaData: {
        type: Object,
        default: () => undefined,
      },
      productPosition: {
        type: Number,
        required: false,
        default: undefined,
      },
    },
    setup(props) {
      const crossSellingType = inject('crossSellingType');
      const getCurrentSeletedColor = inject('getCurrentSeletedColor');
      const getDataLayer = inject('getDataLayer');
      const isRichRelevance = inject('isRichRelevance', false);
      const internals = inject('internals');
      const updateHistoryState = inject('updateHistoryState', () => {});
      const productUri = inject('productUri', '');
      const currentPage = inject('currentPage');
      const baseUrl = mapGetter('page/getBaseUrl');
      const getSearchSource = mapGetter('page/getSearchSource');
      const algoliaInfo = mapGetter('page/getAlgoliaInfo');
      const algoliaUser = mapGetter('cart/getAlgoliaUserData');
      AlgoliaEvents.initAlgoliaEventsUrl(algoliaInfo.value?.events);

      const isAlgoliaEnabled = computed(() => {
        return algoliaInfo.value?.enabled && getSearchSource.value === 'algolia';
      });

      const sendProductClick = function () {
        if (props.retailMediaTracking) APIService.getRetailMediaTracking(props.retailMediaTracking);
        if (updateHistoryState) setProductIdInHistory();

        if (props.algoliaData?.query_id) {
          UtilsCookies.setCookie(
            'algolia_pdp',
            JSON.stringify({
              id: props.id,
              query_id: props.algoliaData?.query_id,
              index_name: props.algoliaData?.index_name,
              position: props.productPosition,
            }),
          );
        }

        if (isAlgoliaEnabled.value) {
          const payload = {
            index: props.algoliaData?.index_name,
            queryID: props.algoliaData?.query_id,
            objectIDs: [props.objectId],
            positions: [props.productPosition],
            userToken: algoliaUser.value?.algoliaUserToken,
            authenticatedUserToken: algoliaUser.value?.algoliaAuthenticatedUserToken,
          };
          AlgoliaEvents.sendClickedObjectIDsAfterSearch(payload);
        }

        const deferredLinkToPDPClick = defer(UtilsAnalytics.sendProductPreviewLinkToPDPClick);
        deferredLinkToPDPClick(getDataLayer(), crossSellingType, isRichRelevance);
      };
      const setProductIdInHistory = function () {
        updateHistoryState({ idProduct: props.id, page: currentPage });
      };

      return {
        baseUrl,
        getCurrentSeletedColor,
        internals,
        sendProductClick,
        productUri,
      };
    },
  };
</script>
<style lang="less">
  .product_preview-title {
    &-bold {
      font-weight: bold !important;
    }
  }
</style>
